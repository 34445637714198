<template>
    <section class="body">
        <a href="https://wa.link/9acf3r" class="whaFix">
            <img src="../../assets/whatsappIcon.png" alt="">
            <div class="cta">
                <p>¿Te interesa conocer más? <br>
                    Contáctanos por <span>Whatsapp</span></p>
            </div>
        </a>
        <hero ciudad="en San Luis Rio Colorado" />
        <section class="proyectosMovil">
            <h2>Clientes que han <br> confiado en nosotros:</h2>

            <div class="heroBrands">

                <div class="Brand" data-aos="fade-right" data-aos-easing="ease-in-out" data-aos-duration="500"
                    data-aos-delay="100">
                    <img src="../../assets/logoLegacy.png" alt="">
                    <a href="#proyectos"><button>Ver Proyecto <img class="Btnimg" src="../../assets/arrow4.svg"
                                alt=""></button></a>
                </div>

                <div class="Brand" data-aos="fade-right" data-aos-easing="ease-in-out" data-aos-duration="500"
                    data-aos-delay="200">
                    <img src="../../assets/logoProdoMax.png" alt="">
                    <a href="#proyectos"><button>Ver Proyecto <img class="Btnimg" src="../../assets/arrow4.svg"
                                alt=""></button></a>
                </div>

                <div class="Brand" data-aos="fade-right" data-aos-easing="ease-in-out" data-aos-duration="500"
                    data-aos-delay="300">
                    <img src="../../assets/logoCenterLine.png" alt="">
                    <a href="#proyectos"><button>Ver Proyecto <img class="Btnimg" src="../../assets/arrow4.svg"
                                alt=""></button></a>
                </div>

                <div class="Brand" data-aos="fade-right" data-aos-easing="ease-in-out" data-aos-duration="500"
                    data-aos-delay="400">
                    <img src="../../assets/logoFlexNGate.png" alt="">
                    <a href="#proyectos"><button>Ver Proyecto <img class="Btnimg" src="../../assets/arrow4.svg"
                                alt=""></button></a>
                </div>
            </div>
        </section>
        <slider ciudad="en San Luis Rio Colorado" />
        <servicios ciudad="en San Luis Rio Colorado" />
        <nosotros  />

        <Proyectos />

        <Formulario />

        <LandingFooter mostrar="false" ciudad="San Luis Rio Colorado" />


    </section>
</template>



<style scoped>
* {
    scroll-behavior: smooth;
}

.proyectosMovil {
    display: flex;
    flex-direction: column;
    justify-content: center;
    background: url(../../assets/NosotrosBg.jpeg);
    background-size: cover;
    padding: 12vw;
    gap: 4vw;
}

.proyectosMovil h2 {
    margin: 0;
    font-size: 4vw;
    font-family: var(--MOSB_Light);
    font-weight: 200;
    color: var(--color-5);
    text-align: center;
}

.heroBrands {
    display: flex;
    flex-direction: column;
    gap: 2vw;
}

.Brand {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.Brand img {
    width: 46vw;
}

.Brand button {
    font-size: 4vw;
    font-family: var(--MOSB_Light);
    font-weight: 200;
    color: var(--color-5);
    background-color: transparent;
    border: none;
    padding: 2vw 5vw;
}

.Brand button img {
    width: 7vw;
    transform: translateY(0.5vw);
}

.whaFix {
    position: fixed;
    bottom: 3vw;
    right: 4vw;
    z-index: 900;
    filter: drop-shadow(0vw 0vw 1vw #00000046);

}

.cta {
    position: absolute;
    top: 0.2vw;
    right: 0.5vw;
    background-color: #F2F2F2;
    padding: 0.2vw 0.8vw;
    font-size: 0.625VW;
    z-index: -15;
    border-radius: 0.5vw;
    width: 0vw;
    overflow: hidden;
    transition: all 300ms;

}

.cta p {
    margin: 0;
    text-wrap: nowrap;
    color: #000;
}

.cta p span {
    font-weight: 700;
}

.whaFix img {
    height: 2.7604166666666665VW;
    width: 2.7604166666666665VW;
}

.whaFix:hover .cta {
    width: 10vw;
}

@media(min-width: 769px) {
    .proyectosMovil {
        display: none;
    }
}

@media(max-width: 769px) {
    .whaFix img {
        height: 8.760417VW;
        width: 8.760417VW;
    }

    .cta {
        top: .5vw;
        right: 2.5vw;
        background-color: #F2F2F2;
        padding: 0.5vw 2vw;
        font-size: 2.3VW;
        z-index: -15;
        border-radius: 0.5vw;
        width: 0vw;
        overflow: hidden;
        transition: all 300ms;

    }

    .whaFix:hover .cta {
        width: 35vw;
    }
}
</style>

<script src="https://cdn.jsdelivr.net/npm/swiper@11/swiper-element-bundle.min.js"></script>

<script>
import hero from '../../components/landing/hero.vue';
import nosotros from '../../components/landing/Nosotros.vue';
import slider from '../../components/landing/ProductosSlider.vue';
import Proyectos from '../../components/landing/proyectos.vue';
import servicios from '../../components/landing/LandingServicios.vue';
import LandingFooter from '../../components/landing/LandingFooter.vue';
import Formulario from '../../components/landing/Formulario.vue'

export default {
    name: "maquinado-industrial",
    components: {
        nosotros, hero, slider, servicios, LandingFooter, Proyectos, Formulario,
    },
    data() {
        return {
        }
    },
    metaInfo: {
        title: 'Maquinado Industrial en San Luis Rio Colorado', //Titulo de la pagina web
        meta: [
            {
                name: 'description',
                content: 'Maquinado Industrial en San Luis Rio Colorado: especializados en torno, fresadora, mantenimiento y reparación de maquinaria. Enfocados en agricultura, minería, automotriz, y mecánica de precisión.'
            }
        ]
    }
}
</script>